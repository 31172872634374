var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h6"},[_vm._v("GIAO DỊCH")])]),_c('v-card-text',[_c('Datatables',{attrs:{"headers":_vm.headers,"items":_vm.itemsWithIndex,"controller":_vm.controller},scopedSlots:_vm._u([{key:"item.Status",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","color":_vm.TransactionStatus[item.Status].c}},[_vm._v(" "+_vm._s(_vm.TransactionStatus[item.Status].t)+" ")])]}},{key:"item.SoTien",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":_vm.TransactionStatus[item.Status].c}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.DoiTien(item.SoTien))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.GhiChu))])])]}},{key:"item.Start",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","color":"blue"}},[_vm._v(" "+_vm._s(_vm.DoiTien(item.Start))+" ")])]}},{key:"item.After",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","color":_vm.TransactionStatus[item.Status].c}},[_vm._v(" "+_vm._s(_vm.DoiTien(item.After))+" ")])]}},{key:"item.DongBang",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.doIsFreeze(item))+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.ThoiGian(item.createdAt))+" ")]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.ThoiGian(item.updatedAt))+" ")]}}],null,true)})],1)],1),_c('loading',{attrs:{"status":_vm.loading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }