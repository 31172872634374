<template>
  <v-card flat>
    <v-card-title>
      <v-row>
        <slot name="tools" />
        <v-spacer />
        <v-col
          cols="12"
          md="6"
          lg="4"
          xl="4"
        >
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Tìm kiếm nhanh"
            single-line
            hide-details
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-data-table
        v-model="Selected"
        :loading="loading"
        :headers="headers"
        :items="items"
        :search="search"
        loading-text="Đang tải dữ liệu..."
        :footer-props="footer_props"
        :items-per-page="PerPage"
        :show-select="multiple"
        item-key="_id"
      >
        <template
          v-for="header in headers"
          #[`item.${header.value}`]="{ item }"
        >
          <slot
            :name="[`item.${header.value}`]"
            :item="item"
          >
            {{ getVal(item, header.value) }}
          </slot>
        </template>
        <slot />
        <template #no-data>
          <br/>
          <v-alert
            border="left"
            colored-border
            type="warning"
          >
            <span
              class="orange--text text-center"
            >
              KHÔNG CÓ DỮ LIỆU
            </span>
          </v-alert>
        </template>
        <template #[`item.controller`]="{ item }">
          <template v-if="controller !== undefined">
            <Controller
              :item="item"
              :controls="controller"
              :group="group"
            />
          </template>
          <template v-if="item.controller !== undefined">
            <Controller
              :item="item"
              :controls="item.controller"
              :group="group"
            />
          </template>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import Controller from '@/components/base/controller.vue'
export default {
  components: {
    Controller,
  },
  props: {
    itemKey:{
      type:String,
      default:undefined,
    },
    loading:{
      type:Boolean,
      default: false,
    },
    headers: {
      type:Array,
      default: ()=>{return []},
      required:true,
    },
    items: {
      type:Array,
      default: ()=>{return []},
      required:true,
    },
    controller: {
      type:Array,
      default: ()=>{return []},
    },
    title: {
      type:String,
      default: "",
    },
    group: {
      type:Boolean,
      default: true
    },
    multiple: {
      type:Boolean,
      default: false
    },
    PerPageText:{
      type: String,
      default: 'Dữ liệu mỗi trang',
      required: false,
    },
    PerPageOption: {
      type: Array,
      default: ()=>{return [50,100,150,200]},
      required: false,
    },
    PerPage: {
      type: Number,
      default: 20,
      required: false,
    },
    value:{
      type:Array,
      default: ()=>{return []},
    }
  },
  data () {
    return {
      filter: {},
      search: "",
      isUpdated: false,
      Selected:this.value,
    }
  },
  computed: {
    footer_props(){
      return {itemsPerPageText:this.PerPageText,'items-per-page-options':this.PerPageOption};
    },
    itemsWithIndex: function(){
      return this.items.map(
        (items, index) => ({
          ...items,
          index: index + 1
        }))
    }
  },
  watch:{
    Selected(v){
      this.$emit('update:value',v);
    }
  },
  mounted(){

  },
  methods: {
    getVal(item, path) {
      return path.split(".").reduce((res, prop) => res[prop], item);
    },
  },
}
</script>