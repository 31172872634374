<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="text-h6">GIAO DỊCH</span>
      </v-card-title>
      <v-card-text>
        <Datatables
          :headers="headers"
          :items="itemsWithIndex"
          :controller="controller"
        >
          <template #[`item.Status`]="{ item }">
              <v-btn
                text
                :color="TransactionStatus[item.Status].c"
              >
                {{TransactionStatus[item.Status].t}}
              </v-btn>
            </template>
            <template #[`item.SoTien`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    v-bind="attrs"
                    v-on="on"
                    :color="TransactionStatus[item.Status].c"
                  >
                    {{DoiTien(item.SoTien)}}
                  </v-btn>
                </template>
                <span>{{item.GhiChu}}</span>
              </v-tooltip>
            </template>
            <template #[`item.Start`]="{ item }">
              <v-btn
                text
                color="blue"
              >
                {{DoiTien(item.Start)}}
              </v-btn>
            </template>
            <template #[`item.After`]="{ item }">
              <v-btn
                text
                :color="TransactionStatus[item.Status].c"
              >
                {{DoiTien(item.After)}}
              </v-btn>
            </template>
            <template #[`item.DongBang`]="{ item }">
              {{doIsFreeze(item)}}
            </template>
            <template #[`item.createdAt`]="{ item }">
              {{ThoiGian(item.createdAt)}}
            </template>
            <template #[`item.updatedAt`]="{ item }">
              {{ThoiGian(item.updatedAt)}}
            </template>
        </Datatables>
      </v-card-text>
    </v-card>
    <loading :status="loading" />
  </div>
</template>

<script>
import Header from '@/components/base/pagehead.vue'
import Datatables from '@/components/base/datatables.vue'
import loading from '@/components/base/loading.vue'
import MyAccount from '@/modules/my.js';
import { Toast, DoiTien, ThoiGian } from '@/helpers'
import { TransactionStatus } from '@/helpers/status'
import moment from 'moment-timezone'

export default {
  watch: {
  },
  components: {
    loading,
    Datatables,
    Header,
  },
  data () {
    return {
      ChargeValid: false,
      loading: false,
      items: [],
      chargeData: {},
      headers: [
        { text: 'STT', align: 'center', sortable: true,value: 'index',width:"auto",class:"primary secondary--text text-center" },
        { text: 'Số tiền', align: 'center', sortable: true,value: 'SoTien',width:"auto",class:"primary secondary--text text-center" },
        { text: 'Trước', align: 'center', sortable: true, value: 'Start',width:"auto",class:"primary secondary--text text-center" },
        { text: 'Sau', align: 'center', sortable: true, value: 'After',width:"auto",class:"primary secondary--text text-center" },
        { text: 'Hành động', align: 'center', sortable: false,value: 'Status',width: "auto",class:"primary secondary--text text-center" },
        { text: 'Đóng băng', align: 'center', sortable: true, value: 'DongBang',width: "auto",class:"primary secondary--text text-center" },
        { text: 'Tạo', align: 'center', sortable: true, value: 'createdAt',width: "auto",class:"primary secondary--text text-center" },
        { text: 'Cập nhật', align: 'center', sortable: true, value: 'updatedAt',width: "auto",class:"primary secondary--text text-center" },
      ],
      Box: {
        Charge:false,
      },
      Valid:{
        Charge: false,
      },
      Loader:{
        Charge: false,
      },
      TransactionStatus,
    }
  },
  computed:{
    templateCharge(){
      return {
        id:undefined,
        sotien:0,
        freeze: true,
        ghichu: '',
      }
    },
    itemsWithIndex(){
      return this.items.map(
        (items, index) => ({
          ...items,
          index: index + 1,
          SoTien: (items.Status === 'rut') ? '-'+items.SoTien:items.SoTien,
        }))
    },
    controller(){
      let controller = []
      return controller
    }
  },
  methods:{
    ...MyAccount,
    DoiTien,
    ThoiGian,
    doIsFreeze(item){
      const created = moment(item.createdAt).tz('Asia/Ho_Chi_Minh').format('DD/MM/YYYY HH:MM');
      const freeze = moment(item.DongBang).tz('Asia/Ho_Chi_Minh').format('DD/MM/YYYY HH:MM');
      if(created === freeze) return 'Không'
      return freeze
    },
    Callback(resp){
      if(resp.success){
        this.doDownload()
      }
    },
    doDownload(){
      this.loading = true;
      this.Transaction().then(json=>{
        const {data} = json
        if(data){
          this.items = data
        }else{
          Toast.Error('Không thể tải dữ liệu !!!');
        }
      })
      .finally(()=>{
        this.loading = false;
      })
    }
  },
  mounted(){
    this.doDownload()
  }
}
</script>

<style>

</style>